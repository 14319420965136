.gallery {
	
}
.gallery__item {
	position: relative;
	display:block;
	float:left;
	width:16.66666%;
}
.gallery__image {
	border: 1px solid $border;
	opacity: 0.8;
}

.gallery__item:hover .gallery__image {
	opacity: 1;
	transition:opacity 300ms;
}
.gallery__item:after {
	display: block;
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 3rem;
	height: 3rem;
	background: url('../images/svg/zoom-in.svg') no-repeat center;
	background-size: 1.5rem;
	border-radius:50%;
	background-color: $primary;
	opacity: 0;
}
.gallery__item:hover:after {
	opacity:1;
}

@include breakpoint(medium down) {
	.gallery__item {
		width: 33.3333%;
	}
}
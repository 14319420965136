.video {
	position:relative;
	border: 1px solid $border;
}
.video video {
	display:block;
	width: 100%;
	height: auto;
}
.video:after, .video:before {
	content: '';
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	pointer-events:none;
}
.video:after {
	width: 80px;
	height: 80px;
	background: $primary;
	background: -moz-radial-gradient(center, ellipse cover, $primary 0%, darken($primary,10%) 100%);
	background: -webkit-radial-gradient(center, ellipse cover, $primary 0%,darken($primary,10%) 100%);
	background: radial-gradient(ellipse at center, $primary 0%,darken($primary,10%) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$primary', endColorstr='darken($primary,10%)',GradientType=1 );
	border-radius: 50%;
	opacity:0.75;
}
.video:before {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 15px 0 15px 30px;
	border-color: transparent transparent transparent white;
	z-index: 2;
	margin-left: 3px;
}
.video:hover {
	cursor:pointer;
}
.video:hover:after {
	opacity:1;
}

.video--hide-wrap.video:after,.video--hide-wrap.video:before {
	display: none;
}
.panel {
	overflow: hidden;
	padding: 1.5rem 0 1.5rem 0;
}
.panel--pading-top {
	padding-bottom:0;
}
.panle--border-bot {
	border-bottom: 1px dashed $border;
}

.panel--silver {
	margin-right:.9375rem;
	margin-left:.9375rem;
	padding: 1rem;
	//border: 1px solid $border;
	background: rgba(0,0,0,0.07);
	margin-bottom: 2rem;
	border-radius:3px;
}

.panel__image {
	height: auto;
	border: 1px solid $light-gray;
	margin-bottom:1rem;
}
.panel__image--left {
	float:left;
	width: 30%;
	margin-bottom: 0;
}
.panel__image--right {
	float:right;
	width: 30%;
	margin-bottom: 0;
}
.panel__content {
	
}
.panel__image.panel__image--left + .panel__content {
	margin-left: 33%;
}
.panel__image.panel__image--right + .panel__content {
	margin-left: 0;
	margin-right: 33%;
}
@include breakpoint(medium down) {
	.panel__image--left, .panel__image--right {
		width: 40%;	
	}
	.panel__image.panel__image--right + .panel__content {
		margin-right: 43%;
	} 
	.panel__image.panel__image--left + .panel__content {
		margin-left: 43%;
	}
}
@include breakpoint(small down) {
	.panel__image--left, .panel__image--right {
		width: 100%;
		float: none;
		margin-bottom: 1rem;
	}
	.panel__image.panel__image--right + .panel__content, .panel__image.panel__image--left + .panel__content {
		margin: 0;
	}
}
/*icon panel*/
.panel__icon {
	float: left;
	width: 80px;
}
.panel__icon + .panel__content {
	margin-left: 95px;
}
.panel--icon .panel__title {
	font-size:1.25em;
}
.panel--icon .panel__text {
	font-size:1em;
	line-height:1.25em;
}

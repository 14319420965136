.top-menu {
	background: white;
	border-top:3px solid $primary;
	box-shadow: 0 0 5px $secondary;
}
.top-menu__list {
	margin: 0;
	padding-left: 2rem;
	overflow: hidden;
}
.top-menu__list-item {
	display: block;
	float: left;
}
.top-menu__link {
	display: block;
	padding: 18px;
	font-size:1.25rem;
	color:$secondary;
}
.top-menu__link:hover, .top-menu__link--active {
	color:$primary;	
	position: relative;
}
.top-menu__link--active:after {
	content: '';
	dispaly:block;
	width: 0;
	height: 0;
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	
	border-style: solid;
	border-width: 8px 8px 0 8px;
	border-color: $primary transparent transparent transparent;
}
.top-menu__logo-trihorse {
	margin-top: 10px;
	display: block;
}
.top-menu__logo-fingercar { 
	margin-top: 17px;
	margin-bottom: 17px;
	display: block;
}
.top-menu__mobile {
	margin-top:12px;
}
/*.top-menu__lang*/
.top-menu__lang {
	margin: 0;
	margin-right: 1em;
}
.top-menu__lang-item {
	display: block;
	float: left;
}
.top-menu__lang-link {
	display: block;
	margin-top: 19px;
	padding: 0 5px;
	font-size:1.1rem;
	color:$secondary;
}

/* .mobile-menu */
.mobile-menu {
	
}
.mobile-menu__list {
	margin: 0;
}
.mobile-menu__list-item {
	display: block;
	border-bottom:1px solid $border;
}
.mobile-menu__link {
	display: block;
	font-weight:500;
	padding: 0.3rem 0.5rem;
	font-size:1.2rem;
}
.mobile-menu__link:hover {
	background:$border;
}
.mobile-menu__title {
	display: block;
	font-weight:500;
	padding: 0.3rem 0.5rem;
	font-size:1.2rem;
}
/*Lang menu*/
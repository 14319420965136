.quote {
	margin-top: 1.5rem;
}
.quote__text {
	font-family: $heading-font-family;
	text-align: center;
	font-size: 2.6rem;
}
.quote__link {
	float: right;
}
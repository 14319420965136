.button {
	background: $primary;
	color:white;
	padding: 0.6em 0.6em;
	font-weight:500;
}
.button--large {
	font-size:1.3em;
}

.button--secondary {
	background: white;
	border: 1px solid $border;
	color: $text-color;
}
.button--secondary:hover {
	border-color:$secondary;
}

.button--expanded {
	display: block;
	text-align: center;
}
//
.button__icon {
	width: 1.2em;
	height: 1.2em;
	vertical-align: top;
	margin-top: 0.1em;
	margin-right: 0.2em;
}

/**/
.button .menu-icon {
	margin-left: 0.5rem;
}
/**/
.button-image {
	opacity:0.92;
}
.button-image:hover{
	opacity:1;
}
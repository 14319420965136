//proměné typografie

$text-color: #404040;
$quiet-color: $light-gray;
$loud-color: darken($text-color,10%);
$heading-color: $text-color;

$link-color: $primary;
$link-hover-color: saturate($primary, 20%);
$link-visited-color: $primary;

$base-font-family: 'Calibri', Helvetica, Roboto, Arial, sans-serif;
$base-font-weight: 300;


$heading-font-family:  'Amatic SC', cursive;
$heading-font-weight: 400;

body {
  color: $text-color;

  font-family: $base-font-family;
  font-weight: $base-font-weight;
  line-height: 1.4;
}

.link {
  color: $link-color;
  text-decoration: none;
  
  &:visited {
    color: $link-visited-color;
  }
  
  &:hover, &:visited:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
}


.quiet { 
	font-color: $quiet-color; 
}
.loud  { 
	color: $loud-color; 
}

.italic { 
	font-style: italic; 
}
.bold  { 
	font-weight: 500;
	 @extend .loud; 
}

.h1, .h2, .h3, .h4, .h5, .h6 { 
	font-family: $base-font-family; 
	font-weight: $heading-font-weight;  
	margin: 0 0 0.2em 0; 
	color: $heading-color;
	letter-spacing: 0.1em;
}
.h1, .h2 { 
	font-family: $heading-font-family; 
	position: relative;
	overflow: hidden;
	text-align: center;
	color: $primary;
}
.h1:before, .h1:after, .h2:before, .h2:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '';
    background-color: $border;
    margin-left: 2%;
}
.h1:before, .h2:before {
    margin-left: -52%;
    text-align: right;
}


.h3, .h4 { 
	line-height: 1.3;
}
.h1 { 
	font-size: 3.75em;
}
.h2 { 
	font-size: 3em;
	margin-bottom: 0.5em;
}
.h3 { 
	font-size: 1.6em;
}
.h4 { 
	font-size: 1.4em;
}
.h5 { 
	font-size: 1.1em;
}
.lead {
	@extend .bold;
	font-size:1.5em;
}

.p {
	font-size:1.2em;
}

.center {
	text-align:center;
	display: block;
}
.center.h1, .center.h2 {
	border-left: none;
	margin-left: 0;
	padding-left: 0;
}
.center.h1:before, .center.h2:before  {
	display: block;
	content: '';
	position: absolute;
	left: 0;
	bottom: -0.35em;	
	width: 100%;
	border-bottom:3px solid $primary;
	width: 20%;
	left: 50%;
	transform: translateX(-50%);
}


.block-margins { 
	margin:0 0 1rem 0;
}

.unordered-list { 
	padding: 0;
	li {
		list-style: none;
		padding-left:2em;
		position: relative;
		line-height: 1.5em;
		margin-bottom: 0.5em;
		&:before {
			content: '';
			display: block;
			width: 0.625em;
			height: 0.625em;
			background: $primary;
			position: absolute;
			left: 0;
			top: 0.4375em;
		}
	}
}
.ordered-list { 
	list-style: decimal;
	padding-left: 1em;
		
	li {
		padding-left: 1em;
		line-height: 1.5em;
		margin-bottom: 0.5em;
	}
}
.unordered-list ul, .ordered-list ol {
	margin-top:0.5em;
}

.text-left   { text-align: left   !important; }
.text-center { text-align: center !important; }
.text-right  { text-align: right  !important; }


.typography {
  a { @extend .link; }

  i, em { @extend .italic; }
  b, strong { @extend .bold; }
  
  h1 { @extend .h1; }
  h2 { @extend .h2; }
  h3 { @extend .h3; }
  h4 { @extend .h4; }
  h5 { @extend .h5; }
  h6 { @extend .h6; }
  
  p, ul, ol, pre { @extend .block-margins; }
  
  p.lead { @extend .lead}
  
  p {@extend .p}
  
  ul { @extend .unordered-list; }
  ol { @extend .ordered-list; }

}

@include breakpoint(large down){
	.p {
		font-size:1.1em;
		line-height: 1.4em;
	}
	.h1 { 
		font-size: 3.3em;
	}
	.h2 { 
		font-size: 2.7em;
		margin-bottom: 0.5em;
	}
	.h3 { 
		font-size: 1.5em;
	}
	.h4 { 
		font-size: 1.3em;
	}
	.h5 { 
		font-size: 1.1em;
	}
}

@include breakpoint(medium down){
	.p {
		font-size:1em;
	}
	.h1 { 
		font-size: 3em;
	}
	.h2 { 
		font-size: 2.5em;
	}
	.h3 { 
		font-size: 1.4em;
	}
	.h4 { 
		font-size: 1.2em;
	}
	.h5 { 
		font-size: 1em;
	}
}
.section {
	padding:4rem 0;
	background: #fff;
	overflow:hidden;
}

.section--gray  {
	background-color:$gray-light;
}
.section--dark {
	background-color:$secondary;
	color: white;
}
.section--slim {
	padding-top:2vh;
	padding-bottom:2vh;	
}
.section--shorter {
	padding-top:1rem;
	padding-bottom:1rem;	
}
.section--short {
	padding-top:0;
	padding-bottom:0;	
}
.section--short-top {
	padding-top:0;
}
.section--short-bot {
	padding-bottom: 0;
}
.section--border {
	border-top:1px solid $border;
	border-bottom:1px solid $border;
}
.section--border-top {
	border-top:1px solid $border;
}

.section--cars {
	background: url('../images/h-1.png') no-repeat left bottom,
				url('../images/h-2.png') no-repeat right bottom
				white;
}
@include breakpoint(1400px down) {
	.section--cars {
		background-size:12%;
	}
}
@include breakpoint(medium down) {
	.section--cars {
		background-image:none;	
	}
}